<template>
  <div class="coreActivity">
    <img src="~@/assets/img/business@2x.jpg" class="head_img"/>
    <div class="body">
      <div class="first">
        <div class="left">
          <div @mouseenter="activate = 0">
            <img src="~@/assets/img/text_01_brown@2x.png" v-if="activate === 0">
            <img src="~@/assets/img/text_01_grey@2x.png" v-else>
            <div>
              <div :style="{color:activate === 0?'#C6A68E':''}">互联网地产</div>
              <div>以精益求精铸造专业级品质的理念，在产品开发、存储技术、数据挖掘、营销推广等领域取得多项成果。</div>
            </div>
          </div>
          <div @mouseenter="activate = 1">
            <img src="~@/assets/img/text_02_brown@2x.png" v-if="activate === 1">
            <img src="~@/assets/img/text_02_grey@2x.png" v-else>
            <div>
              <div :style="{color:activate === 1?'#C6A68E':''}">健康医疗</div>
              <div>通过深度合作，让更多人进一步享受到多元化、全方位、高效专业的呵护，开启全新互联网医疗健康服务体验。</div>
            </div>
          </div>
          <div @mouseenter="activate = 2">
            <img src="~@/assets/img/text_03_brown@2x.png" v-if="activate === 2">
            <img src="~@/assets/img/text_03_grey@2x.png" v-else>
            <div>
              <div :style="{color:activate === 2?'#C6A68E':''}">智慧社区</div>
              <div>利用如今物联网、5G、大数据、人工智能等新一代的信息技术，实现社区管理的数字化、网络化、智能化、互动化和协同化。</div>
            </div>
          </div>
          <div @mouseenter="activate = 3">
            <img src="~@/assets/img/text_04_brown@2x.png" v-if="activate === 3">
            <img src="~@/assets/img/text_04_grey@2x.png" v-else>
            <div>
              <div :style="{color:activate === 3?'#C6A68E':''}">健康管理</div>
              <div>产品形态多样化、多元化，未来的健康产业不仅限于此，更有着广阔的发展空间。</div>
            </div>
          </div>
        </div>
        <div class="right">
          <img src="~@/assets/img/business_01@2x.jpg">
          <div>麦菲斯抓住机遇，在医疗大健康领域全面发力，以实力赢得信誉，合作伙伴包括众多集团，并积极与各平台等开展深度合作，逐步打造体系完整、协同高效的医疗健康产业生态圈，迈向更高起点。</div>
        </div>
      </div>
      <div class="second">
        <div @click="$router.push('/industryTrendsDetails/4')">
          <img src="~@/assets/img/tarde_c1@2x.png"/>
          <div>中小地产企业如何活下去 | 地产新势</div>
          <div>2022-01-27</div>
          <div>过去的2021年，房地产经历冰火两重天，上半年市场上行，房企拿地积极；下半年市场转冷，流动性风险加剧，尤其是四季度以来，包括恒大、佳兆业、阳光城、奥园等多家规模房企深陷危机。</div>
        </div>
        <div @click="$router.push('/industryTrendsDetails/5')">
          <img src="~@/assets/img/tarde_c2@2x.png"/>
          <div>2022年医疗健康五大趋势，这些设备是风口</div>
          <div>2021-11-18</div>
          <div>
            美国《福布斯》双周刊网站在近日的报道中，列出了2022年医疗保健领域的五大技术趋势：远程医疗、个性化医疗、基因组学和可穿戴设备等领域将迎来高速增长，业界人士也将进一步利用人工智能（AI）、云计算、虚拟现实和物联网（IoT）等技术，开发并提供新的更好的疗法和服务。
          </div>
        </div>
      </div>
      <div class="second">
        <div @click="$router.push('/industryTrendsDetails/6')">
          <img src="~@/assets/img/tarde_c3@2x.png"/>
          <div>楼市展望与行业前瞻：地产行业在阵痛中寻找未来</div>
          <div>2021-11-08</div>
          <div>今年的房地产市场没有“金九”，也没有“银十”。
            克而瑞研究中心发布的2021年1月-9月房地产企业销售TOP100排行榜显示，百强房企单月全口径销售金额同比下降36%。10月，房地产市场持续降温，逾8成百强房企单月业绩同比降低，其中44家房企业同比降幅大于30%。
          </div>
        </div>
        <div @click="$router.push('/industryTrendsDetails/7')">
          <img src="~@/assets/img/tarde_c4@2x.png"/>
          <div>全球独角兽企业500强报告发布：医疗健康赛道前景看好</div>
          <div>2021-09-25</div>
          <div>中新网青岛9月25日电
            由青岛市人民政府和中国人民大学中国民营企业研究中心联合主办的“第三届全球独角兽企业500强大会(2021)”日前在青岛市举办。会议期间全球独角兽企业500强大会秘书长解树江发布了《全球独角兽企业500强发展报告(2021)》。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "coreActivity",
  data() {
    return {
      activate: 0
    }
  }
}
</script>

<style scoped lang="scss">
.coreActivity {
  .body {
    padding-top: 101px;
    width: 65%;
    margin: 0 auto;

    .first {
      display: flex;
      justify-content: space-between;
      margin-bottom: 81px;

      .left {
        flex: .42;

        & > div {
          display: flex;
          justify-content: space-between;
          margin-bottom: 40px;
          cursor: pointer;

          img {
            width: 83px;
            height: 56px;
            padding-right: 36px;
            transition: all 1s ease-out;
          }

          & > div {
            & > div {
              &:nth-child(1) {
                font-size: 20px;
                font-weight: bold;
                line-height: 25px;
                padding-bottom: 28px;
                transition: all 0.5s ease-out;
              }

              &:nth-child(2) {
                font-size: 15px;
                color: #C7C7C8;
              }
            }
          }
        }
      }

      .right {
        flex: .38;
        font-size: 0;
        margin-right: 45px;

        img {
          width: 100%;
          height: 430px;
        }

        div {
          background: rgba(198, 166, 142, 0.87);
          font-size: 13px;
          color: #FFFFFF;
          line-height: 30px;
          padding: 36px 28px;
        }
      }
    }

    .second {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      & > div {
        flex: .49;
        cursor: pointer;

        & > img {
          width: 100%;
          height: 350px;
          padding-bottom: 30px;
        }

        & > div {
          &:nth-child(2) {
            font-size: 19px;
            font-weight: bold;
            color: #4C4C4C;
            line-height: 26px;
          }

          &:nth-child(3) {
            font-size: 16px;
            font-weight: 500;
            color: #DFDFDF;
            line-height: 20px;
            margin: 20px 0;
          }

          &:nth-child(4) {
            font-size: 14px;
            font-weight: 400;
            color: #686868;
            line-height: 24px;
            margin-bottom: 80px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
      }
    }
  }
}
</style>